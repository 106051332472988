import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import ScrollTo from "react-scroll-to-top";
import ScrollToTop from "../component/ScrollToTop";
import { useRef, useState } from "react";

export default function Package() {
      const [selectedOptions, setSelectedOptions] = useState({});
    
      const handleOptionChange = (columnIndex, value) => {
        setSelectedOptions((prev) => ({
          ...prev,
          [columnIndex]: value, // Update the selected value for this column
        }));
      };

    const tableData = [
        {
          pricing: "Aizelle lite",
          // accountSize: "$6,000",
          fee: "$49",
          profitTarget: "P1.8%, P2.5%",
          time: "2nd Phase -4 week",
          maxDailyLoss: "5%",
          maxOverallLoss: "10%",
          balanceDrawdown: "✓",
          minTradingDays: "5 Days",
          profitSplit: "95%",
          newsTrading: "✓",
          firstReward: "Monthly"
        },
        {
          pricing: "Aizelle pro",
          // accountSize: "$15,000",
          fee: "$39",
          profitTarget: "10%",
          time: "4 weeks",
          maxDailyLoss: "5%",
          maxOverallLoss: "10%",
          balanceDrawdown: "✓",
          minTradingDays: "5 Days",
          profitSplit: "95%",
          newsTrading: "✓",
          firstReward: "Monthly"
        },
        {
          pricing: "Aizelle Express",
          // accountSize: "$25,000",
          fee: "$99",
          profitTarget: "20%",
          time: "-",
          maxDailyLoss: "5%",
          maxOverallLoss: "10%",
          balanceDrawdown: "✓",
          minTradingDays: "10 Days",
          profitSplit: "95%",
          newsTrading: "✓",
          firstReward: "Monthly"
        },
        {
          pricing: "Aizelle demo",
          // accountSize: "$50,000",
          fee: "$29",
          profitTarget: "0%",
          time: "-",
          maxDailyLoss: "4%",
          maxOverallLoss: "8%",
          balanceDrawdown: "✓",
          minTradingDays: "5 Days",
          profitSplit: "-",
          newsTrading: "✖",
          firstReward: "0 Days"
        },
        // {
        //   pricing: "1",
        //   accountSize: "$100,000",
        //   fee: "$449",
        //   profitTarget: "P1.8%, P2.5%",
        //   time: "1st Phase - 4 weeks, 2nd Phase - 8 weeks",
        //   maxDailyLoss: "5% ($5,000)",
        //   maxOverallLoss: "10% ($10,000)",
        //   balanceDrawdown: "✓",
        //   minTradingDays: "5 Days",
        //   profitSplit: "95%",
        //   newsTrading: "✓",
        //   firstReward: "Monthly"
        // },
        // {
        //   pricing: "1",
        //   accountSize: "$200,000",
        //   fee: "$799",
        //   profitTarget: "P1.8%, P2.5%",
        //   time: "1st Phase - 4 weeks, 2nd Phase - 8 weeks",
        //   maxDailyLoss: "5% ($10,000)",
        //   maxOverallLoss: "10% ($20,000)",
        //   balanceDrawdown: "✓",
        //   minTradingDays: "5 Days",
        //   profitSplit: "95%",
        //   newsTrading: "✓",
        //   firstReward: "Monthly"
        // }
      ];
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_padding privacypolicy">
                    <div className="container">
                        <div>
                        <h2 className="heading_h2 mb-lg-4 mb-3">Compare All Our Challenges</h2>
                        </div>
                        <div className="table_round table-responsive shadow-sm mt-5">
                            <table className="compare-table table table-bordered m-0">
                                <thead>
                                <tr className="sub_para text-wrap">
  <th className="first-cell-bg text-start">
    <img 
      src="assets/images/logo/favicon.png" 
      alt="logo" 
      className="me-2 mb-2" 
      style={{ display: "block", }} 
    />
    Pricing Plan
  </th>
  {tableData.map((data, index) => (
    <th key={index}>{data.pricing}</th>
  ))}
</tr>

                                    {/* <tr>
                                        <th className="first-cell-bg">Account Size</th>
                                        {tableData.map((data, columnIndex) => (
                                            <th key={columnIndex}>
                                                <form>
                                                    {["$6000", "$15000", "$25000", "$50000"].map(
                                                        (option, optIndex) => (
                                                            <label key={optIndex} className="d-block">
                                                                <input
                                                                    type="radio"
                                                                    name={`option-${columnIndex}`} // Unique name per column
                                                                    value={option}
                                                                    checked={selectedOptions[columnIndex] === option}
                                                                    onChange={() => handleOptionChange(columnIndex, option)}
                                                                />{" "}
                                                                {option}
                                                            </label>
                                                        )
                                                    )}
                                                </form>
                                                <button className="btn btn-primary w-100 mt-2">
                                                    Get Plan <br />
                                                    {data.fee}
                                                </button>
                                            </th>
                                        ))}
                                    </tr> */}
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="first-cell-bg">Profit Target</td>
                                        {tableData.map((data, index) => (
                                            <td key={index}>{data.profitTarget}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="first-cell-bg">Time</td>
                                        {tableData.map((data, index) => (
                                            <td key={index}>{data.time}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="first-cell-bg">Maximum Daily Loss</td>
                                        {tableData.map((data, index) => (
                                            <td key={index}>{data.maxDailyLoss}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="first-cell-bg">Maximum Overall Loss</td>
                                        {tableData.map((data, index) => (
                                            <td key={index}>{data.maxOverallLoss}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="first-cell-bg">Balance Based Drawdown</td>
                                        {tableData.map((data, index) => (
                                            <td key={index}>{data.balanceDrawdown}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="first-cell-bg">Minimum Trading Days</td>
                                        {tableData.map((data, index) => (
                                            <td key={index}>{data.minTradingDays}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="first-cell-bg">Profit Split Upto</td>
                                        {tableData.map((data, index) => (
                                            <td key={index}>{data.profitSplit}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="first-cell-bg">News Trading</td>
                                        {tableData.map((data, index) => (
                                            <td key={index}>{data.newsTrading}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="first-cell-bg">First Reward</td>
                                        {tableData.map((data, index) => (
                                            <td key={index}>{data.firstReward}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                <Footer />
                <ScrollTo smooth={true} className="aizelle_top" />
            </ScrollToTop>
        </>
    );
}
