import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import ScrollTo from "react-scroll-to-top";
import ScrollToTop from "../component/ScrollToTop";
export default function Privacypolicy() {
  return (
    <>
      <ScrollToTop>
        <Header />
        <section className="section_padding privacypolicy">
          <div className="container">
            <div className="scroll">
              <div className="scroll-inner">
                <h2 className="heading_h2 mb-lg-4 mb-3">
                  <span>Privacy </span>Policy
                </h2>

                <div className="sub_para">
                  <p>
                    We offer the best services around - from installations to
                    repairs, maintenance, and more!
                  </p>

                  <p>
                    At Aizelle, we prioritize the privacy and security of our users'
                    personal information. This Privacy Policy outlines how we collect,
                    use, and safeguard your data:
                  </p>
                  <ul>
                    <li>
                      Information Collection: We collect personal information such as
                      names, email addresses, and contact details to provide and
                      improve our services, personalize user experiences, and
                      communicate updates.
                    </li>
                    <li>
                      Data Usage: Your information is used solely for the purposes
                      stated above and is not shared with third parties without your
                      consent, except for trusted partners assisting in service
                      operations.
                    </li>
                    <li>
                      Data Security: We implement robust security measures to protect
                      your data against unauthorized access, alteration, or
                      disclosure.
                    </li>
                    <li>
                      Cookies: We may use cookies to enhance your browsing experience
                      and collect usage data. You can disable cookies through your
                      browser settings.
                    </li>
                    <li>
                      Third-Party Links: Our website may contain links to third-party
                      websites. We are not responsible for their privacy practices or
                      content.
                    </li>
                    <li>
                      Data Retention: We retain your information only for as long as
                      necessary to fulfill the purposes outlined herein.
                    </li>
                    <li>
                      Children’s Privacy: Our services are not intended for
                      individuals under 18 years of age.
                    </li>
                    <li>
                      Updates: We may update this Privacy Policy periodically. You
                      will be notified of significant changes through email or by
                      notice on our website.
                    </li>
                    <li>
                      Contact Us: If you have questions or concerns about this Privacy
                      Policy or your data, please contact us at [insert contact
                      information].
                    </li>
                  </ul>
                  <p>
                    By using our website and services, you consent to the terms
                    outlined in this Privacy Policy.
                  </p>
                </div>
                <h4 className="heading_h6 mt-md-5 mt-3">Trading Disclaimer</h4>
                <p className="sub_para">
                  Trading and investing in financial markets carry inherent risks,
                  and all activities undertaken based on information provided by
                  Aizelle are at your own risk. Our trading disclaimer is as follows:
                </p>
                <h4 className="heading_h6 mt-md-5 mt-3">Trading Disclaimer</h4>
                <div className="sub_para">
                  <p>
                    The information provided on Aizelle's website is for educational and
                    informational purposes only and should not be construed as
                    financial or investment advice. Trading and investing in financial
                    markets carry a high level of risk and may not be suitable for all
                    investors. Past performance is not indicative of future results.
                  </p>
                  <p>
                    No representation is being made that any account will or is likely
                    to achieve profits or losses similar to those discussed on this
                    website. The strategies discussed may not be suitable for your
                    specific financial situation and risk tolerance. You should
                    carefully consider your investment objectives, level of
                    experience, and risk appetite before making any investment
                    decisions.
                  </p>
                  <p>
                    The content provided on this website, including but not limited to
                    articles, videos, and trading signals, is provided "as is" and
                    without any warranty of accuracy, completeness, or reliability.
                    Aizelle and its affiliates shall not be liable for any errors,
                    omissions, or delays in the content or for any actions taken in
                    reliance thereon.
                  </p>
                  <p>
                    Before engaging in any trading or investment activities, it is
                    recommended that you seek advice from an independent financial
                    advisor to ensure you understand the risks involved and to assess
                    whether such activities are suitable for you based on your
                    individual circumstances.
                  </p>
                  <p>
                    By accessing this website, you agree that Aizelle and its affiliates
                    shall not be liable for any direct, indirect, incidental,
                    consequential, or punitive damages arising out of your use or
                    reliance on the information provided herein.
                  </p>
                  <p>
                    These documents outline our commitment to protecting your privacy
                    and ensuring transparency regarding trading activities. Aizelle
                    strives to operate with integrity and compliance with legal
                    standards, safeguarding both our users and our business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <ScrollTo smooth={true} className="aizelle_top" />
      </ScrollToTop>
    </>
  );
}
